/* Contest.css */
.contest-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.contest-header-left {
  flex: 1;
  padding-right: 20px;
}

.contest-header-left h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.contest-header-left p {
  font-size: 1.2em;
  margin: 5px 0;
}

.contest-header-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.contest-header-right img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.button-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #4f46e5;
  border: none;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  margin-top: 10px;
}

.button-link:hover {
  background-color: #756fe6;
}

/* Media query for mobile view */
@media (max-width: 600px) {
  .contest-header {
    flex-direction: column;
  }

  .contest-header-left {
    padding-right: 0;
    width: 100%;
  }

  .contest-header-right {
    display: none;
  }
}
