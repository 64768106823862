/* Blog.css */

/* Main wrapper for blog */
/* .blogs-wrapper {
  background-color: var(--first-color, #f7f7f7);
} */

/* General button styling */
button {
  background-color: var(--primary-color, #007bff);
  border-radius: 30px;
  font-size: 1.1rem;
  border: none;
  color: #fff;
  padding: 0.8rem 1.5rem;
  cursor: pointer;
}

/* Events container styling */
.events-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  position: static;
}

/* Header title styling */
.event-header-title {
  text-align: center;
}

/* Events grid styling */
.events {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 70px auto;
  position: relative;
}

/* Single event card styling */
.event {
  width: 280px;
  margin: 10px;
  height: 330px;
  overflow: hidden;
  border-radius: 5px;
  background-color: var(--third-color, #333);
  box-shadow: 4px 3px 8px 0px rgba(112, 89, 169, 0.6);
  transition-duration: 0.3s;
  cursor: pointer;
}

/* Thumbnail container styling */
.event-thumb-container {
  background-color: #000;
  position: relative;
  height: 172px;
  color: #fff;
  text-align: center;
}

/* Thumbnail image styling */
.event-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 172px;
}

/* Event description styling */
.event-description {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* Event name styling */
.event-name {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Event info styling */
.event-info {
  color: #fff;
  font-weight: 200;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* Responsive styling */
@media (max-width: 960px) {
  button {
    font-size: 0.6rem;
    padding: 0.4rem 1rem;
  }

  .text-lg {
    font-size: 1.3rem;
  }

  .text-md {
    font-size: 1rem;
  }
}

@media (max-width: 700px) {
  .events {
    flex-direction: column;
  }
}
