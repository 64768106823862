.pricing-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
  max-width: 300px;
}

.pricing-card h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
}

.pricing-card p {
  margin-bottom: 10px;
  font-size: 1em;
}

.pricing-card ul {
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.pricing-card ul li {
  margin-bottom: 5px;
}

.pricing-card .btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4f46e5;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.pricing-card .btn:hover {
  background-color: #0056b3;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 30px;
  /* gap: 10px; */
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  text-align: center;
  margin-bottom: 20px;
}
