.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.card-container .MuiGrid-item {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-container .MuiGrid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.1);
}
