/* BlogPost.css */

/* General styling for the blog post container */
.blog-post {
  max-width: 800px; /* Maximum width of the blog post card */
  margin: 20px auto; /* Center the card on the page */
  padding: 20px; /* Padding inside the card */
  background-color: #2b2b2b; /* Background color for the card */
  border-radius: 5px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Title styling */
.blog-post-title {
  font-size: 2rem; /* Font size for the title */
  margin-bottom: 15px; /* Space below the title */
  color: #fff; /* Use a CSS variable for color */
}

/* Meta information styling */
.blog-post-meta {
  margin-bottom: 20px; /* Space below the meta information */
  color: #fff; /* Color for meta info */
}

/* Styling for images in the blog post */
.blog-post-image {
  max-width: 100%; /* Ensure images do not exceed the card width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px; /* Rounded corners for images */
  margin: 15px 0; /* Space above and below images */
}

/* Styling for markdown content */
.blog-post-content {
  overflow: hidden; /* Hide overflow if any */
  padding: 20px; /* Padding around the content */
  box-sizing: border-box; /* Include padding in total width/height */
}

/* Styling for images inside markdown */
.blog-post-content img {
  max-width: 100%; /* Responsive width for images */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Centering */
  margin: 15px auto; /* Margin for spacing */
  border-radius: 5px; /* Rounded corners */
}

/* Headings in markdown */
.blog-post-content h1,
.blog-post-content h2,
.blog-post-content h3 {
  color: #fff; /* Use a CSS variable for heading color */
  margin-top: 20px; /* Space above headings */
  margin-bottom: 10px; /* Space below headings */
}

/* Paragraph styling in markdown */
.blog-post-content p {
  line-height: 1.6; /* Line height for readability */
  color: #fff; /* Color for text */
  margin: 10px 0; /* Space between paragraphs */
}

/* Link styling in markdown */
.blog-post-content a {
  color: #007acc; /* Link color */
  text-decoration: none; /* Remove underline from links */
}

/* Link hover effect */
.blog-post-content a:hover {
  text-decoration: underline; /* Underline on hover */
}

/* Additional styling for lists */
.blog-post-content ul,
.blog-post-content ol {
  margin: 10px 0; /* Space above and below lists */
  padding-left: 20px; /* Indentation for lists */
}

/* Media Queries for Responsive Design */
@media (max-width: 600px) {
  .blog-post {
    padding: 15px; /* Reduce padding on smaller screens */
  }

  .blog-post-title {
    font-size: 1.5rem; /* Smaller title font size */
  }
}
